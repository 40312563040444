/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';

import {
 React, useEffect, useState, Fragment, useContext, 
} from 'react';
import i18next from 'i18next';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faCrown,
  faUser,
  faLanguage,
  faWallet,
  faSearch,
  faPlus,
  faCaretDown,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import SearchBar from './SearchBar';
import Logout from './buttons/Logout';
import logoLt from '../assets/images/pcdl-logo-lt.png';
import logoBlk from '../assets/images/pcdl-logo-blk.png';
import logoMb from '../assets/images/pcdl-logo-1.png';
import Context from '../context/context';
import userImg from '../assets/images/icons/user.png';
import { refreshUserData } from '../assets/scripts/admin-scripts';
import Icon from './misc/Icon';

const navigation = [
  { title: 'Home', href: '/', current: true },
  { title: 'Browse', href: '/browse', current: true },
  { title: 'Challenges', href: '/categories', current: false },
  { title: 'Growth', href: '/solutions', current: false },
  // { title: 'Series', href: '/series-beta/bni8yt6', current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Navigation() {
  const location = useLocation();
  const history = useNavigate();

  const [loggedIn, setIsLoggedin] = useState(false);

  const [menu, setMenu] = useState(false);
  const [lan, setLan] = useState({});

  const [isDesktop, setDesktop] = useState(window.innerWidth > 1024);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 1024);
  };

  useEffect(() => {
    if (localStorage.getItem('pcdl.token')) {
      setIsLoggedin(true);
    }
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  useEffect(() => {
    if (localStorage.getItem('pcdl.token')) {
      refreshUserData();
    }
  }, []);

  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const { translation, translationActions } = useContext(Context);

  const user = JSON.parse(localStorage.getItem('pcdl.user'));
  const [show, setShow] = useState(false);

  const listenScrollEvent = (event) => {
    if (window.scrollY < 73) {
      setShow(false);
    }
    if (window.scrollY > 70) {
      setShow(true);
    }
  };

  const setLanguage = () => {
    for (let i = 0; i < translation.translations.length; i += 1) {
      if (translation.translations[i].code === localStorage.i18nextLng) {
        setLan(translation.translations[i]);
      }
    }
  };

  useEffect(() => {
    setLanguage();

    window.addEventListener('scroll', listenScrollEvent);

    return () => window.removeEventListener('scroll', listenScrollEvent);
  }, []);

  return (
    <div
      className={`sticky top-0 left-1/2 ${
        location.pathname.match(
          /(watch|dictionary)/,
        ) ? 'm-0 px-0' : 'md:my-4 md:px-6'
      } transform w-full  z-50`}
    >
      <Disclosure
        as="nav"
        className="flex items-center top-0 z-50 w-full px-4 py-3 lg:py-0 text-left bg-[#1D1D1D] shadow-lg md:rounded-md transition-all duration-100"
      >
        {!location.pathname.match(/(login|signin|subscription|plans|app)/) ? (
          ({ open, close }) => (
            <div className="w-full mx-auto">
              <div className="relative flex mx-auto items-center justify-between h-full">
                <div className="flex flex-row items-center">
                  <div className="flex-shrink-0 mr-6">
                    <Link
                      to="/"
                      className={`${
                        location.pathname.match(/(join)/)
                        && 'pointer-events-none'
                      }`}
                    >
                      <img
                        className="h-11 w-auto hidden lg:block"
                        src={logoLt}
                        alt="Workflow"
                      />
                      <img
                        className="lg:hidden h-11 xs:h-12 w-auto relative"
                        src={logoLt}
                        alt="Workflow"
                      />
                    </Link>
                  </div>
                  <div
                    className={`flex items-center px-2 lg:px-0 hidden lg:block ${
                      location.pathname.match(/(welcome|join)/)
                      && 'hidden lg:hidden'
                    }`}
                  >
                    <div className="hidden w-full lg:block">
                      <div className="flex items-center">
                        <Link
                          className="transition flex flex-row items-center block px-4 py-3 rounded-md text-xs xl:text-sm font-medium text-white hover:bg-neutral-800"
                          to="/"
                          key="Home"
                        >
                          {t('Home')}
                        </Link>

                        <Menu as="div" className="relative flex-shrink-0 my-3">
                          <div className="flex justify-center">
                            <Menu.Button className="transition flex flex-row items-center block px-4 py-3 rounded-md text-xs xl:text-sm font-medium text-white text-white hover:bg-neutral-800">
                              Browse
                              <FontAwesomeIcon
                                className="block text-2xl w-3 h-3 ml-1"
                                aria-hidden="true"
                                icon={faCaretDown}
                              />
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="mt-5 rounded-md font-medium origin-top-right absolute left-0 w-[15rem] overflow-hidden shadow-lg bg-[#1d1d1d] ring-1 ring-black ring-opacity-5 focus:outline-none">
                              {/* <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="/wallet"
                                    className={classNames(
                                    active ? 'bg-[#1d1d1d] hover:bg-neutral-800' : '',
                                    'block px-4 py-3 text-sm text-white',
                                  )}
                                  >
                                    {t('New')}
                                  </a>
                              )}
                              </Menu.Item> */}
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="/categories"
                                    className={classNames(
                                      active
                                        ? 'bg-[#1d1d1d] hover:bg-neutral-800'
                                        : '',
                                      'block px-4 py-3 text-sm text-white',
                                    )}
                                  >
                                    {t('Categories')}
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="/browse"
                                    className={classNames(
                                      active
                                        ? 'bg-[#1d1d1d] hover:bg-neutral-800'
                                        : '',
                                      'block px-4 py-3 text-sm text-white',
                                    )}
                                  >
                                    {t('Alphabetical')}
                                  </a>
                                )}
                              </Menu.Item>
                              {/* <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="/espees"
                                    className={classNames(
                                    active ? 'bg-[#1d1d1d] hover:bg-neutral-800' : '',
                                    'block px-4 py-3 text-sm text-white',
                                  )}
                                  >
                                    {t('Documentaries')}
                                  </a>
                              )}
                              </Menu.Item> */}
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="/series/hGPFmF-VlVI/your-loveworld-specials"
                                    className={classNames(
                                      active
                                        ? 'bg-[#1d1d1d] hover:bg-neutral-800'
                                        : '',
                                      'block px-4 py-3 text-sm text-white',
                                    )}
                                  >
                                    {t('Your Loveworld Specials')}
                                  </a>
                                )}
                              </Menu.Item>
                            </Menu.Items>
                          </Transition>
                        </Menu>

                        <Link
                          className="transition flex flex-row items-center block px-4 py-3 rounded-md text-xs xl:text-sm font-medium text-white hover:bg-neutral-800"
                          to="/solutions"
                          key="Challenges"
                        >
                          {t('Everyday Solutions')}
                        </Link>
                        <Link
                          className="transition flex flex-row items-center block px-4 py-3 rounded-md text-xs xl:text-sm font-medium text-white hover:bg-neutral-800 hover:cursor-pointer"
                          to="/search"
                          key="Challenges"
                        >
                          {t('PCDL Search')}
                        </Link>
                        <Menu as="div" className="relative flex-shrink-0">
                          <div className="flex justify-center">
                            <Menu.Button className="transition flex flex-row items-center block px-4 py-3 rounded-md text-xs xl:text-sm font-medium text-white hover:bg-neutral-800">
                              More
                              <FontAwesomeIcon
                                className="block text-2xl w-3 h-3 ml-1"
                                aria-hidden="true"
                                icon={faCaretDown}
                              />
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="mt-5 rounded-md font-medium origin-top-right absolute left-0 w-[15rem] overflow-hidden shadow-lg bg-[#1d1d1d] ring-1 ring-black ring-opacity-5 focus:outline-none">
                              {/* <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="/settings"
                                    className={classNames(
                                    active ? 'bg-[#1d1d1d] hover:bg-neutral-800' : '',
                                    'block px-4 py-3 text-sm text-white flex flex-row items-center',
                                  )}
                                  >
                                    {t('Testimonies')}
                                  </a>
                              )}
                              </Menu.Item> */}
                              {/* <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="/search"
                                    className={classNames(
                                      active
                                        ? 'bg-[#1d1d1d] hover:bg-neutral-800'
                                        : '',
                                      'block px-4 py-3 text-sm text-white flex flex-row items-center',
                                    )}
                                  >
                                    {t('PCDL Search')}
                                    <FontAwesomeIcon
                                      className="block text-pcdl-4 text-2xl w-3 h-3 ml-1"
                                      aria-hidden="true"
                                      icon={faPlus}
                                    />
                                  </a>
                                )}
                              </Menu.Item> */}
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="/categories/XR7p8b5icds/languages"
                                    className={classNames(
                                      active
                                        ? 'bg-[#1d1d1d] hover:bg-neutral-800'
                                        : '',
                                      'block px-4 py-3 text-sm text-white flex flex-row items-center',
                                    )}
                                  >
                                    {t('Languages')}
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="https://pastorchrisdigitallibrary.org/livetv/"
                                    className={classNames(
                                      active
                                        ? 'bg-[#1d1d1d] hover:bg-neutral-800'
                                        : '',
                                      'block px-4 py-3 text-sm text-white flex flex-row items-center',
                                    )}
                                  >
                                    {t('PCDL TV')}
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="/solutions/dictionary"
                                    className={classNames(
                                      active
                                        ? 'bg-[#1d1d1d] hover:bg-neutral-800'
                                        : '',
                                      'block px-4 py-3 text-sm text-white',
                                    )}
                                  >
                                    {t('Dictionary')}
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="/wordfest"
                                    className={classNames(
                                      active
                                        ? 'bg-[#1d1d1d] hover:bg-neutral-800'
                                        : '',
                                      'block px-4 py-3 text-sm text-white',
                                    )}
                                  >
                                    {t('Wordfest 6')}
                                  </a>
                                )}
                              </Menu.Item>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-auto ">
                  <div className="flex space-x-2 md:space-x-4 items-center justify-end">
                    <button
                      className={`${
                        location.pathname.match(/(welcome|join)/) && 'hidden'
                      } ${
                        menu ? 'text-black bg-white' : 'text-white'
                      } transition fill-white  hover:text-black hover:fill-black hover:bg-white block p-2 rounded-full text-xs xl:text-sm font-bold`}
                      type="button"
                      onClick={(e) => setMenu(!menu)}
                    >
                      <p className="flex flex-row items-center duration-100 font-bold">
                        <MagnifyingGlassIcon
                          className="w-4"
                          aria-hidden="true"
                        />
                        {/* <span className="hidden md:block">{t('Search')}</span> */}
                      </p>
                    </button>
                    <Link
                      className={`${
                        location.pathname.match(/(welcome|join)/) && 'hidden'
                      } hidden sm:block transition fill-white text-white hover:text-black hover:fill-black hover:bg-white block px-2 py-[.8rem] rounded-md text-xs xl:text-sm font-medium ${
                        location.pathname === '/pcdl4kids'
                        && 'text-black fill-black bg-white'
                      }`}
                      to="/pcdl4kids"
                    >
                      <p className="flex flex-row items-center duration-100 ml-2 md:mr-2 font-medium">
                        Kids
                      </p>
                    </Link>
                    {/* Profile dropdown */}
                    {loggedIn ? (
                      <Menu as="div" className="relative flex-shrink-0">
                        <div className="flex justify-center">
                          <Menu.Button className="bg-transparent transition ease-out duration-100 text-white hover:bg-white hover:text-black items-center rounded-full flex text-xs xl:text-sm focus:outline-none">
                            <span className="sr-only">Open user menu</span>
                            {/* {user && (
                              <p className="hidden lg:block ml-2 mr-4 font-bold">
                                <span className="mr-1">{user.title}</span>
                                {user.first_name}
                              </p>
                            )} */}
                            <img
                              className="h-11 w-11 rounded-full"
                              src={`${
                                user.avatar_url === ''
                                || user.avatar_url === null
                                  ? userImg
                                  : user.avatar_url
                              }`}
                              alt="User Profile img"
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 overflow-hidden rounded-md shadow-lg bg-[#1d1d1d] ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                              {({ active }) => (
                                <p
                                  className={classNames(
                                    active
                                      ? 'bg-[#1d1d1d] hover:bg-neutral-800'
                                      : '',
                                    ' block flex items-center px-4 py-2 bg-pcdl text-sm text-white font-medium',
                                  )}
                                >
                                  <FontAwesomeIcon
                                    className="block text-2xl w-5 h-5 mr-2"
                                    aria-hidden="true"
                                    icon={faCrown}
                                  />
                                  {t(
                                    JSON.parse(
                                      localStorage.getItem('pcdl.user'),
                                    ).subscription_name,
                                  )}
                                </p>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/wallet"
                                  className={classNames(
                                    active
                                      ? ''
                                      : '',
                                    'transition block flex items-center px-4 py-2 bg-neutral-200 text-sm font-medium hover:bg-neutral-300',
                                  )}
                                >
                                  <FontAwesomeIcon
                                    className="block text-2xl w-5 h-5 mr-2"
                                    aria-hidden="true"
                                    icon={faWallet}
                                  />
                                  {JSON.parse(
                                    localStorage.getItem('pcdl.user'),
                                  ).wallet_balance.toFixed(2)}
                                  <span className="ml-1"> Espees</span>
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/settings"
                                  className={classNames(
                                    active
                                      ? 'bg-[#1d1d1d] hover:bg-neutral-800'
                                      : '',
                                    'block px-4 py-3 text-sm text-white',
                                  )}
                                >
                                  {t('Profile')}
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/wallet"
                                  className={classNames(
                                    active
                                      ? 'bg-[#1d1d1d] hover:bg-neutral-800'
                                      : '',
                                    'block px-4 py-3 text-sm text-white',
                                  )}
                                >
                                  {t('Wallet')}
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/coupons"
                                  className={classNames(
                                    active
                                      ? 'bg-[#1d1d1d] hover:bg-neutral-800'
                                      : '',
                                    'block px-4 py-3 text-sm text-white',
                                  )}
                                >
                                  {t('Your Coupons')}
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/redeem/coupon"
                                  className={classNames(
                                    active
                                      ? 'bg-[#1d1d1d] hover:bg-neutral-800'
                                      : '',
                                    'block px-4 py-3 text-sm text-white',
                                  )}
                                >
                                  {t('Redeem Coupon')}
                                </a>
                              )}
                            </Menu.Item>
                            {/* <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/espees"
                                  className={classNames(
                                    active
                                      ? 'bg-[#1d1d1d] hover:bg-neutral-800'
                                      : '',
                                    'block px-4 py-3 text-sm text-white',
                                  )}
                                >
                                  {t('Purchase Espees')}
                                </a>
                              )}
                            </Menu.Item> */}
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/search"
                                  className={classNames(
                                    active
                                      ? 'bg-[#1d1d1d] hover:bg-neutral-800'
                                      : '',
                                    'lg:hidden block px-4 py-3 text-sm flex flex-row items-center text-white',
                                  )}
                                >
                                  {t('PCDL Search')}
                                  <FontAwesomeIcon
                                    className="block text-pcdl-4 text-2xl w-3 h-3 ml-1"
                                    aria-hidden="true"
                                    icon={faPlus}
                                  />
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/solutions/dictionary"
                                  className={classNames(
                                    active
                                      ? 'bg-[#1d1d1d] hover:bg-neutral-800'
                                      : '',
                                    'lg:hidden block px-4 py-3 text-sm flex flex-row items-center text-white',
                                  )}
                                >
                                  {t('Pastor Chris Dictionary')}
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/pcdl4kids"
                                  className={classNames(
                                    active
                                      ? 'bg-[#1d1d1d] hover:bg-neutral-800'
                                      : '',
                                    'lg:hidden block px-4 py-3 text-sm text-white',
                                  )}
                                >
                                  {t('PCDL4KIDS')}
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/library"
                                  className={classNames(
                                    active
                                      ? 'bg-[#1d1d1d] hover:bg-neutral-800'
                                      : '',
                                    'block px-4 py-3 text-sm text-white',
                                  )}
                                >
                                  {t('Library')}
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/watchlist"
                                  className={classNames(
                                    active
                                      ? 'bg-[#1d1d1d] hover:bg-neutral-800'
                                      : '',
                                    'block px-4 py-3 text-sm text-white',
                                  )}
                                >
                                  {t('Watchlist')}
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/history"
                                  className={classNames(
                                    active
                                      ? 'bg-[#1d1d1d] hover:bg-neutral-800'
                                      : '',
                                    'block px-4 py-3 text-sm text-white',
                                  )}
                                >
                                  {t('History')}
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="/subscriptions"
                                  className={classNames(
                                    active
                                      ? 'bg-[#1d1d1d] hover:bg-neutral-800'
                                      : '',
                                    'block px-4 py-3 text-sm text-white',
                                  )}
                                >
                                  {t('Subscription')}
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  onClick={() => {
                                    history('/');
                                    Logout();
                                    setIsLoggedin(false);
                                  }}
                                  type="button"
                                  className={classNames(
                                    active
                                      ? 'bg-[#1d1d1d] hover:bg-neutral-800'
                                      : '',
                                    'block w-full text-left px-4 py-2 text-sm text-white',
                                  )}
                                >
                                  {t('Logout')}
                                </button>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    ) : (
                      <>
                        <a href="/signup">
                          <button
                            type="button"
                            className={`hidden px-6 py-2.5 transition ml-4 text-black bg-white font-bold text-sm leading-tight rounded-full focus:outline-none focus:ring-0 transition duration-150 ease-in-out ${
                              location.pathname.match(/(join)/)
                                ? 'hidden'
                                : 'md:inline-block'
                            }`}
                          >
                            {t('Sign Up')}
                          </button>
                        </a>
                        <a
                          href="/signin"
                          className={`${
                            location.pathname.match(/(join)/) && 'hidden'
                          }`}
                        >
                          <button
                            type="button"
                            className="flex-row px-6 py-2.5 transition text-white border border-white font-bold text-sm leading-tight rounded-full focus:outline-none focus:ring-0 transition duration-150 ease-in-out flex"
                          >
                            {' '}
                            <FontAwesomeIcon
                              className="h-4 block text-2xl text-white mr-2"
                              aria-hidden="true"
                              icon={faUser}
                            />
                            {t('Sign In')}
                          </button>
                        </a>
                      </>
                    )}
                    <Menu as="div" className="ml-2 relative">
                      <div>
                        <Menu.Button className="bg-transparent rounded-full flex text-sm mx-1 text-white focus:outline-none">
                          <span className="sr-only">Change language</span>
                          {/* <p className="hidden xl:block font-bold text-xs xl:text-sm">
                            {lan.title}
                          </p> */}
                          <span
                            className={`ml-2 flag-icon flag-icon-${lan.countryCode}`}
                          />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="origin-top-right absolute right-0 mt-6 w-48 overflow-hidden rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {translation.translations.map(
                            ({ code, title, countryCode }) => (
                              <Menu.Item key={countryCode}>
                                {({ active }) => (
                                  <button
                                    href="/settings"
                                    type="button"
                                    onClick={() => {
                                      i18next.changeLanguage(code);
                                      setLan({ code, title, countryCode });
                                    }}
                                    disabled={code === localStorage.i18nextLng}
                                    className={classNames(
                                      active
                                        ? 'bg-[#1d1d1d] hover:bg-neutral-800'
                                        : '',
                                      'disabled:opacity-75 w-full text-left block px-4 py-3 text-sm text-white',
                                    )}
                                  >
                                    <span
                                      className={`flag-icon flag-icon-${countryCode} mr-2`}
                                    />
                                    {title}
                                  </button>
                                )}
                              </Menu.Item>
                            ),
                          )}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </div>
              </div>
              {menu && (
                <div className="my-4 max-w-2xl mx-auto drop-shadow-2xl">
                  <SearchBar placeholder="Search" btn={setMenu} />
                </div>
              )}
            </div>
          )
        ) : (
          <div className="mx-auto py-3">
            <Link to="/">
              <img className="h-11 w-auto" src={logoLt} alt="Workflow" />
            </Link>
          </div>
        )}
      </Disclosure>
    </div>
  );
}
